<template>
  <div>
    <div v-if="selectedKey === 'key_11'">
      <key-common
        :key="selectedKey"
        :status_key="'key_11'"
        date_filter_key="status_date"
        date_filter_name="Dispatched Date"
        status_name="RecievedAtDestinationWarehouse"
      />
    </div>
    <div v-if="selectedKey === 'key_12'">
      <key-twelve :status_key="'key_12'" />
    </div>
    <div v-if="selectedKey === 'key_13'">
      <key-common
        :key="selectedKey"
        :status_key="'key_13'"
        date_filter_key="status_date"
        date_filter_name="Assigned Date"
        status_name="Delivered"
      />
    </div>
    <div v-if="selectedKey === 'key_14'">
      <key-fourteen :status_key="'key_14'" />
    </div>
    <div v-if="selectedKey === 'key_15'">
      <key-fifteen :status_key="'key_15'" />
    </div>
    <div v-if="selectedKey === 'key_16'">
      <key-sixteen :status_key="'key_16'" />
    </div>
    <div v-if="selectedKey === 'key_17'">
      <key-seventeen :status_key="'key_17'" />
    </div>
    <div v-if="selectedKey === 'key_18'">
      <key-common
        :key="selectedKey"
        :status_key="'key_18'"
        date_filter_key="orders.created_at"
        date_filter_name="Created Date"
        status_name="ReturnToOriginWarehouse"
      />
    </div>
    <div v-if="selectedKey === 'key_26'">
      <key-twenty-six :status_key="'key_26'" />
    </div>
    <div v-if="selectedKey === 'key_27'">
      <key-common
        :key="selectedKey"
        :status_key="'key_27'"
        date_filter_key="status_date"
        date_filter_name="Returned Date"
        status_name="ReturnToOriginWarehouse(InvalidaDestination)"
      />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions,camelcase
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import KeyCommon from '../../components/status-flow/KeyCommon.vue'
import KeyTwelve from '../../components/status-flow/KeyTwelve.vue'
import KeyFourteen from '../../components/status-flow/KeyFourteen.vue'
import KeyFifteen from '../../components/status-flow/KeyFifteen.vue'
import KeySixteen from '../../components/status-flow/KeySixteen.vue'
import KeySeventeen from '../../components/status-flow/KeySeventeen.vue'
import KeyTwentySix from '../../components/status-flow/KeyTwentySix.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    KeyCommon,
    KeyTwelve,
    KeyFourteen,
    KeyFifteen,
    KeySixteen,
    KeySeventeen,
    KeyTwentySix,
  },
  data() {
    return {
      selectedKey: '',
    }
  },
  mounted() {
    this.getKey()
  },
  methods: {
    getKey() {
      this.selectedKey = this.$route.params.key
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select';
</style>
