<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
    slim
  >
    <form
      ref="formObj"
      :class="gridClass"
      @submit.prevent="handleSubmit(onSubmitWapper)"
    >
      <div
        v-for="(formInput, index) in formStructure"
        :key="index"
        :class="formInput.class || 'col'"
      >
        <slot
          :name="formInput.title"
          :formInputData="formInput"
          :formValue="value"
          :value="value[formInput.value]"
          :setter="setter(formInput.value)"
          :class="formInput.inputClass"
        >
          <ValidationProvider
            #default="{ errors }"
            :vid="formInput.title"
            :name="formInput.title"
            :rules="formInput.validation || ''"
            slim
          >
            <b-form-group
              :description="formInput.description"
              :label="formInput.title"
              :label-class="formInput.labelClass"
              :class="formInput.groupClass"
              :label-for="formInput.value"
              :invalid-feedback="errors[0]"
              :state="errors.length > 0 ? false:null"
            >
              <template #label>
                <slot
                  :name="formInput.value+'_label'"
                >
                  {{ formInput.title }}
                </slot>
              </template>
              <slot
                :name="formInput.title+'_form'"
                :value="value[formInput.value]"
                :setter="setter(formInput.value)"
              >
                <custom-form-input
                  :ref="formInput.ref"
                  :form-input="formInput"
                  :form-data="value"
                  :value="value[formInput.value]"
                  :errors="errors"
                  :disabled="formInput.disabled"
                  @input="setValue(formInput.value,$event)"
                  @builderPaste="handleBuilderPaste"
                  @builderKeyDown="handleBuilderKeyDown"
                />
              </slot>
            </b-form-group>
          </ValidationProvider>
        </slot>
        <div v-if="formInput.searchEnable === 'displaySearchButton'">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn btn-icon custom-button-color mt-2 ml-2"
            :class="isButtonEnable ? '' : 'disabled'"
            @click="searchWaybill(value.waybill_number)"
          >
            <feather-icon
              icon="SearchIcon"
              class="waybillSearch"
            />
          </b-button>
        </div>
      </div>
      <slot name="buttons">
        <button
          ref="submitBtn"
          class="d-none"
          type="submit"
        >
          Submit
        </button>
      </slot>
      <slot name="flag" />
    </form>
    <slot name="footer" />
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CustomFormInput from './CustomFormInput.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider, ValidationObserver, BFormGroup, CustomFormInput,
  },
  props: {
    gridClass: {
      type: String,
      default: () => 'row',
    },
    formStructure: {
      type: Array,
      default: () => [],
    },
    value: undefined,
    onSubmit: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      isButtonEnable: false,
    }
  },
  watch: {
    value(value) {
      this.$emit('input', value)
    },
  },
  created() {
    this.formStructure.filter(x => x.default).forEach(x => { this.value[x.value] = x.default })
  },
  methods: {
    handleBuilderPaste(pastedText) {
      this.$emit('waybillPaste', pastedText)
    },
    handleBuilderKeyDown(waybill) {
      this.$emit('waybillScan', waybill)
    },
    searchWaybill(waybill) {
      this.$emit('searchWaybill', waybill)
    },
    onSubmitWapper() {
      this.onSubmit(this.value)
    },
    clearValidation() {
      this.$refs.form.reset()
    },
    reset() {
      const value = {}
      this.formStructure.filter(x => x.default).forEach(x => { value[x.value] = x.default })
      this.$emit('input', value)
      this.$refs.form.reset()
    },
    setValue(path, data) {
      this.$set(this.value, path, data)
      if (this.value.waybill_number) {
        this.isButtonEnable = true
      } else {
        this.isButtonEnable = false
      }
      this.$emit(`${path}OnChange`, data)
      this.$emit('input', this.value)
      this.$emit(`${path}OnChanged`, data)
    },
    submit() {
      this.$refs.submitBtn.click()
    },
    setter(value) {
      return input => {
        this.$set(this.value, value, input)
        this.$emit('input', this.value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.waybillSearch {
  color: white;
}
</style>
