<template>
  <div>
    <b-overlay
      id="overlay-background"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-table
        :fields="fields"
        :items="rows"
        hover
        responsive
        striped
        show-empty
        @sort-changed="sortChanged"
      >
        <template #empty>
          <TableDataFetching
            :rows="rows"
            :data-loading="dataLoading"
          />
        </template>
        <template #cell(date_time)="data">
          <div class="font-weight-bold">
            {{ __dateTimeFormatter(data.value) }}
          </div>
          <small>{{ data.item.date_time_ago }}</small>
        </template>
        <template #cell(user)="data">
          <div class="font-weight-bold">
            {{ data.value.first_name }} {{ data.value.last_name }}
          </div>
          <small>{{ data.item.user_role }}</small>
        </template>
        <template #cell(reversal_comment)="data">
          <div class="font-weight-bold">
            {{ data.value }}
          </div>
        </template>
        <template #cell(current_status.name)="data">
          <div class="font-weight-bolder">
            {{ data.value }}
          </div>
          <div
            v-if="(data.item.data.status_reason !== null)"
          >
            <small class="font-weight-bold">
              Reason :
            </small>
            <small>{{ data.item.data.status_reason }}</small>
          </div>
        </template>
        <template #cell(previous_status.name)="data">
          <div class="font-weight-bolder">
            {{ data.value }}
          </div>
          <div
            v-if="(data.item.data.previous_status_reason !== null)"
          >
            <small class="font-weight-bold">
              Reason :
            </small>
            <small>{{ data.item.data.previous_status_reason }}</small>
          </div>
        </template>
        <template #cell(data)="data">
          <div>
            <b>Weight :&nbsp;</b><span>{{ data.value.weight || '---' }}</span>
          </div>
          <div>
            <b>Collected COD :&nbsp;</b><span>{{ data.value.collected_cod || '---' }}</span>
          </div>
          <div>
            <b>Delivery Charge :&nbsp;</b><span>{{ data.value.delivery_charge || '---' }}</span>
          </div>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const OrderRepository = RepositoryFactory.get('order')

export default {
  components: {
    BTable,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    order_id: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      dataLoading: true,
      sort: '',
      id: '',
      reversedData: [],
      fields: [
        {
          key: 'date_time',
          label: 'Reversed Date',
          sortable: true,
        }, {
          key: 'user',
          label: 'Reversed By',
          sortable: true,
          tdClass: 'text-left',
        },
        {
          key: 'reversal_comment',
          label: 'Remark',
          sortable: false,
          tdClass: 'text-left',
        },
        {
          key: 'current_status.name',
          label: 'From Status',
          sortable: false,
        },
        {
          key: 'data',
          label: 'Previous Status Details',
          sortable: false,
        },
        {
          key: 'previous_status.name',
          label: 'To Status',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    orderId() {
      return this.order_id
    },
  },
  mounted() {
    this.getOrderReversedDetails()
  },
  methods: {
    async getOrderReversedDetails() {
      try {
        const { data } = await OrderRepository.getOrderReversedDetails(this.orderId)
        this.rows = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.getOrderList()
      }
    },
  },
}
</script>
