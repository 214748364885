<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="btn-icon custom-button-color mt-2"
      :disabled="!waybillNumber"
      @click="fetchData()"
    >
      <feather-icon icon="SearchIcon" />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
  },
  props: {
    waybillNumber: {
      type: String,
      required: true,
    },
  },
  methods: {
    fetchData() {
      this.$emit('search')
    },
  },
}
</script>
<style scoped>
</style>
