<template>
  <div>
    <b-tooltip
      target="waybill-submit-info-icon"
      triggers="hover"
      variant="primary"
    >
      {{ tooltipText }}
    </b-tooltip>
    <feather-icon
      id="waybill-submit-info-icon"
      icon="InfoIcon"
      size="12"
      :style="`position:absolute; top:3; left:${left};`"
      class="text-primary"
    />
  </div>
</template>

<script>
import {
  BTooltip,
} from 'bootstrap-vue'

export default {
  name: 'BInputLabelInfo',
  components: {
    BTooltip,
  },
  props: {
    left: {
      type: Number,
      default: 0,
    },
    tooltipText: {
      type: String,
      default: 'The length of this waybill number does not match the waybill length specified under order settings and as a result auto submit will not work for this waybill',
    },
  },
}
</script>
