export default {
  methods: {
    displayAmountWithCurrency(amount) {
      // eslint-disable-next-line camelcase
      const currency_code = 'Rs '
      // eslint-disable-next-line camelcase
      return `${currency_code} ${Number(amount).toFixed(2)}`
    },
  },
}
