<template>
  <div>
    <b-modal
      id="modal-bulk-action"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="lg"
      title="BULK ACTION"
      class="p-4"
    >
      <template #modal-header>
        <h5
          class="modal-title"
        >
          BULK ACTION
        </h5>
        <button
          v-if="!loadingResponse"
          type="button"
          class="close"
          :disabled="loadingResponse"
          @click="handleCloseButtonClick"
        >
          <span>&times;</span>
        </button>
      </template>
      <h5 class="mb-2 ml-1">
        Waybill Numbers
      </h5>
      <b-badge
        v-for="(waybill, index) in waybillArray"
        :key="index"
        variant="light-primary"
        class="mr-1 mb-1 ml-1"
      >
        {{ waybill }}
      </b-badge>
      <h5
        v-if="this.statusKey === 'key_7' || this.statusKey === 'key_13'"
        class="mt-2 ml-1"
      >
        Collected COD : {{ totalCOD }}
      </h5>
      <b-row class="mt-2">
        <b-col>
          <form-builder
            ref="formbuilderBulkAction"
            v-model="formData"
            :on-submit="onSubmit"
            grid-class="px-1 row"
            :form-structure="formStructure"
          >
            <template slot="buttons">
              <b-row>
                <b-col>
                  <b-button
                    type="submit"
                    class="vh-5 mt-2 ml-1"
                    variant="primary"
                    :disabled="loadingResponse"
                  >
                    {{ loadingResponse ? 'Submitting...' : 'Submit' }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </form-builder>
          <!-- <b-tooltip
            v-if="status_key === 'key_7' || status_key === 'key_9'"
            target="weight-bulk-submit-info-icon"
            triggers="hover"
            variant="primary"
          >
            The order weight should be between {{ order_settings.weight_range_setting.min_weight }} Kg to {{ order_settings.weight_range_setting.max_weight }} Kg
          </b-tooltip>
          <feather-icon
            v-if="status_key === 'key_7' || status_key === 'key_9'"
            id="weight-bulk-submit-info-icon"
            icon="InfoIcon"
            size="12"
            style="position:absolute; top:3; left:110;"
            class="text-primary"
          /> -->
        </b-col>
      </b-row>
      <b-row
        v-if="errors.length > 0"
        class="mt-4"
      >
        <b-col>
          <b-table
            striped
            hover
            responsive
            :items="errors"
            :fields="fields"
          >
            <template #cell(status)="data">
              <b-badge variant="light-danger">
                {{ data ? 'error' : 'success' }}
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BBadge,
  VBModal,
  BCol,
  BRow,
  BButton,
  BTable,
  // BTooltip,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FormBuilder from '@/libs/builders/form-builder/FormBuilder.vue'

const orderRepository = RepositoryFactory.get('order')

function initialState() {
  return {
    open: false,
    formData: {},
    errors: [],
    loadingResponse: false,
    fields: [
      {
        key: 'waybill_number',
        label: 'Waybill Number',
        thClass: 'text-left',
        tdClass: 'text-left',
      },
      {
        key: 'status',
        label: 'Status',
        thClass: 'text-left',
        tdClass: 'text-left',
      },
      {
        key: 'error',
        label: 'Error',
        thClass: 'text-left',
        tdClass: 'text-left',
      },
    ],
  }
}

export default {
  name: 'KeyFourModal',
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BBadge,
    BCol,
    BRow,
    BButton,
    FormBuilder,
    BTable,
    // BTooltip,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    form_structure: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
    },
    // eslint-disable-next-line vue/prop-name-casing
    status_key: {
      type: String,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    selected_waybill_array: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    // eslint-disable-next-line vue/prop-name-casing
    truck_driver: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/prop-name-casing
    order_settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      box: '',
      totalCOD: 0,
      ...initialState(), // Spread the properties from initialState() if necessary
    }
  },
  computed: {
    formStructure() {
      return this.form_structure.filter(n => n.value !== 'waybill_number').map(m => {
        const obj = m
        // obj.class = 'col-12'
        return obj
      })
    },
    statusKey() {
      return this.status_key
    },
    waybillArray() {
      return this.selected_waybill_array
    },
  },
  methods: {
    // eslint-disable-next-line camelcase
    async openModal() {
      await Object.assign(this.$data, initialState())
      this.loadingResponse = false
      this.removeDisabled()
      this.open = true
      if (this.statusKey === 'key_7' || this.statusKey === 'key_13') {
        const relevantRows = this.rows.filter(row => this.waybillArray.includes(row.waybill_number))
        this.totalCOD = relevantRows.reduce((total, row) => total + row.cod, 0)
        this.totalCOD = this.totalCOD.toLocaleString('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    async handleCloseButtonClick() {
      this.open = false
    },
    async onSubmit() {
      const truckDriverEnabled = localStorage.getItem('truck_driver_enabled')
      try {
        this.loadingResponse = true
        const payload = {}
        if (!this.formData.driver_id) {
          this.formData.driver_id = null
        }
        payload.other_details = this.formData
        payload.waybill_numbers = this.waybillArray
        payload.status_key = this.statusKey
        if (this.statusKey === 'key_11') {
          payload.other_details.is_bulk = true
        }
        if (this.statusKey === 'key_10') {
          payload.truck_driver = Boolean(!truckDriverEnabled) || this.truck_driver
        }
        await orderRepository.updateOrderStatus(payload)
        this.loadingResponse = false
        this.showSuccessMessage('Order updated successfully')
        this.open = false
        this.$parent.selectedWaybillArray = []
        this.$parent.selectAllCheckbox = false
        this.$parent.onClickRefresh()
      } catch (e) {
        this.loadingResponse = false
        this.errors = []
        const mainErrorMessage = e.response.data.message
        const detailedErrors = e.response.data.errors || {}

        if (Object.keys(detailedErrors).length > 0) {
          Object.entries(detailedErrors).forEach(([, fieldErrors]) => {
            fieldErrors.forEach(error => {
              const combinedErrorMessage = `${mainErrorMessage} ${error}`
              this.errors.push({ waybill_number: '---', error: combinedErrorMessage })
            })
          })
        } else if (typeof mainErrorMessage === 'string') {
          this.errors.push({ waybill_number: '---', error: mainErrorMessage })
        } else {
          Object.entries(mainErrorMessage).forEach(([, element]) => {
            this.errors.push({ waybill_number: element.waybill_number, error: element.error })
          })
        }
      }
    },
    removeDisabled() {
      this.formStructure.forEach(n => {
        // eslint-disable-next-line no-param-reassign
        n.disabled = false
      })
    },
  },
}
</script>

<style scoped>

</style>
