<template>
  <b-modal
    id="modal-order-view"
    ref="modal"
    v-model="open"
    size="ov-custom"
    centered
    hide-footer
    title="VIEW ORDER DETAILS"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-row class="mb-2">
        <b-col>
          <div class="d-flex align-items-center justify-content-lg-center">
            <order-status-banner
              class="status-bar"
              style="width:100%; font-size: 16px"
              :status-key="order.status.key"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-2">
        <b-col
          cols="12"
          lg="4"
          class="mobile-column"
        >
          <b-badge
            :variant="'light-' + order.status.color"
            class="col-container d-flex justify-content-between "
          >
            <div class="flex-column flex-lg-row mb-1 mt-1 mb-lg-0 text-lg-left text-container">
              <div
                class="ml-lg-1 mr-lg-1"
                style="margin-top: 4px;"
              >
                <div class="font-weight-bolder mb-1 sub-title">
                  {{ order.waybill_number }}
                </div>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  COD: &nbsp;Rs.{{ order.cod }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  Weight: &nbsp;{{ order.weight }}kg
                </h5>
                <h5 class="font-weight-bold font-small-4">
                  Created on: &nbsp;{{ __dateTimeFormatter(order.created_at) }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4 mt-1"
                  style="margin-bottom:3px;"
                >
                  Delivery Attempt: &nbsp;{{ order.delivery_attempts }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4 mt-1"
                  style="margin-top:9px"
                >
                  {{ order.description ? ( order.description.length > 65 ? order.description.substring(0,65) + '---' : order.description ) : 'No description' }}
                </h5>
                <h5
                  v-if="items.has_feedback"
                >
                  <star-rating
                    :rating="items.rating"
                    :max-rating="5"
                    :star-size="20"
                    :show-rating="false"
                    :read-only="true"
                  />
                </h5>
              </div>
            </div>
            <div class="mt-1 mr-1 ">
              <b-avatar
                :text="'C'"
                size="26"
                :variant="myVariant"
              />
            </div>
          </b-badge>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          class="mobile-column"
        >
          <b-badge
            :variant="'light-' + order.status.color"
            class="col-container"
          >
            <div class="flex-column flex-lg-row mb-3 mt-1 mb-lg-0 text-lg-left">
              <div
                class="ml-lg-1 mr-lg-1 text-container"
                style="margin-top: 4px;"
              >
                <div class="font-weight-bolder mb-1 sub-title">
                  Merchant Details
                </div>
                <h5
                  class="font-weight-bolder"
                  style="margin-bottom:3px;"
                >
                  {{ order.merchant_business.business_name }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  {{ order.merchant.name }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  {{ order.merchant.ref_no }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4 opacity-0-per d-none d-lg-flex"
                  style="margin-bottom:1px;"
                >
                  {{ order.merchant.ref_no }}
                </h5>
                <h5 class="font-weight-bold font-small-4">
                  Pickup Address: &nbsp;<b>{{ order.merchant_business.pickup_address }}</b>
                </h5>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  Origin City: &nbsp;<b>{{ order.origin_city.name }}</b>
                </h5>
                <h5 class="font-weight-bold font-small-4">
                  Origin Warehouse: &nbsp;<b>{{ order.origin_warehouse.name }}</b>
                </h5>
              </div>
            </div>
          </b-badge>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          class="mobile-column"
        >
          <b-badge
            :variant="'light-' + order.status.color"
            class="col-container"
          >
            <div class="flex-column flex-lg-row mb-3 mt-1 mb-lg-0 text-lg-left">
              <div
                class="ml-lg-1 mr-lg-1 text-container"
                style="margin-top: 4px;"
              >
                <div class="font-weight-bolder mb-1 sub-title">
                  Customer Details
                </div>
                <h5
                  class="font-weight-bolder"
                  style="margin-bottom:3px;"
                >
                  {{ order.customer_name }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  {{ order.customer_address }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  {{ order.customer_email }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  {{ order.customer_phone }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4"
                  style="margin-bottom:3px;"
                >
                  {{ order.customer_secondary_phone }}
                </h5>
                <h5
                  class="font-weight-bold font-small-4 mt-1"
                  style="margin-bottom:3px;"
                >
                  Destination City: &nbsp;<b>{{ order.destination_city.name }}</b>
                </h5>
                <h5 class="font-weight-bold font-small-4">
                  Destination Warehouse: &nbsp;<b>{{ order.destination_warehouse.name }}</b>
                </h5>
              </div>
              <div
                v-if="order.temporary_warehouse_name && order.temporary_warehouse_name !==''"
                class="font-weight-bold font-small-4 ml-1"
              >
                Temporary Warehouse: &nbsp;<b>{{ order.temporary_warehouse_name }}</b>
              </div>
            </div>
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mx-2 mt-2 mb-2">
        <b-col class="d-flex justify-content-lg-end ml-1 ml-lg-0">
          <div
            v-for="flag_type in order.order_flags.map(n => n.order_flag)"
            :key="flag_type"
          >
            <b-badge :variant="getFlagBadgeColor(flag_type.type)">
              {{ flag_type.name }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <b-tabs
        content-class="pt-1 min-height-59vh"
        fill
      >
        <b-tab
          title="Order History"
          class="p-2"
        >
          <app-timeline>
            <app-timeline-item
              v-for="(data, index) in timelineData"
              :key="index"
              :variant="data.status.color ? data.status.color : 'primary'"
              :icon="data.status.icon"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <b-badge
                  :variant="data.status.color ? data.status.color : 'primary'"
                  class="mb-2 mobile-column"
                >
                  <div class="text-center">
                    <h6 class="mb-0 text-sm text-wrap">
                      {{ data.status.name }}
                    </h6>
                  </div>
                </b-badge>
                <small class="date-container">{{ __dateTimeFormatter(data.date_time) }} {{ data.date_time_ago }}</small>
              </div>

              <p>{{ data.reason || '' }}</p>
              <div v-if="(index=='0' && order.rider_signature != '')">
                <b-button
                  v-if="order.status.key == 'key_7' || order.status.key == 'key_13' || order.status.key == 'key_14'"
                  variant="outline-primary"
                  size="sm"
                  class="view-sign-button"
                  @click="openSignatureModal()"
                >
                  View Signature
                </b-button>
              </div>
              <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                <b-media>
                  <template #aside>
                    <b-avatar
                      :src="`https://picsum.photos/200/200?random=${index}`"
                      class="mr-30"
                      variant="light-success"
                    />
                  </template>
                  <h5>{{ data.user.first_name || '' }} {{ data.user.last_name || '' }}</h5>
                  <span>{{ data.user_role || '' }}</span>
                </b-media>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-tab>
        <b-tab
          title="Rider Deposit History"
          class="px-2"
        >
          <app-timeline v-if="financeTimelineData.staff_deposit">
            <div
              class="shadow rounded-sm mb-3 w-100 bg-light-secondary"
              style="padding:10px;"
            >
              <div class="font-small-5 font-weight-bold text-center text-dark">
                Ref No : {{ financeTimelineData.staff_deposit[0].ref_no }}
              </div>
            </div>
            <app-timeline-item
              v-for="(data, index) in financeTimelineData.staff_deposit"
              :key="index"
              :variant="'light-'+data.status.color ? data.status.color : 'primary'"
              :icon="data.status.icon"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ data.status.name }}</h6>
                <small class="text-muted">{{ __dateTimeFormatter(data.date_time) }} | {{ data.date_time_ago }}</small>
              </div>
              <h5 class="font-small-3">
                {{ data.deposit_amount }}
              </h5>
              <p>{{ data.reason || '' }}</p>
              <div
                v-if="data.user !== 'Automatically Generated'"
                class="d-flex flex-sm-row flex-column justify-content-between align-items-start"
              >
                <b-media>
                  <template #aside>
                    <b-avatar
                      :src="`https://picsum.photos/200/200?random=${index}`"
                      class="mr-30"
                      variant="light-success"
                    />
                  </template>
                  <h6>{{ data.user.first_name || '' }} {{ data.user.last_name || '' }}</h6>
                  <span class="text-muted">{{ data.user_role || '' }}</span>
                </b-media>
              </div>
              <h6
                v-else
                class="font-weight-bolder"
              >
                {{ data.user }}
              </h6>
            </app-timeline-item>
          </app-timeline>
          <div
            v-else
            class="d-flex justify-content-center align-items-center"
          >
            This order has no recorded staff deposit history
          </div>
        </b-tab>
        <b-tab
          title="Warehouse Deposit History"
          class="px-2"
        >
          <app-timeline v-if="financeTimelineData.warehouse_deposit || financeTimelineData.walking_warehouse_deposit">
            <b-row>
              <b-col
                v-if="isWalkingDeposit"
                :cols="financeTimelineData.warehouse_deposit ? 6 : 12"
              >
                <div
                  class="shadow rounded-sm mb-3 w-100 bg-light-secondary"
                  style="padding:10px;"
                >
                  <div class="font-small-5 font-weight-bold text-center text-dark">
                    Advance Deposit - Ref No : {{ financeTimelineData.walking_warehouse_deposit[0].ref_no }}
                  </div>
                </div>
                <app-timeline-item
                  v-for="(data, index) in financeTimelineData.walking_warehouse_deposit"
                  :key="index"
                  :variant="'light-'+data.status.color ? data.status.color : 'primary'"
                  :icon="data.status.icon"
                >
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <div>
                      <h6>{{ data.status.name }}</h6>
                      <h5 class="font-small-3">
                        {{ data.deposit_amount }}
                      </h5>
                    </div>
                    <small class="text-muted">{{ __dateTimeFormatter(data.date_time) }} | {{ data.date_time_ago }}</small>
                  </div>
                  <p>{{ data.reason || '' }}</p>
                  <div
                    v-if="data.user !== 'Automatically Generated'"
                    class="d-flex flex-sm-row flex-column justify-content-between align-items-start"
                  >
                    <b-media>
                      <template #aside>
                        <b-avatar
                          :src="`https://picsum.photos/200/200?random=${index}`"
                          class="mr-30"
                          variant="light-success"
                        />
                      </template>
                      <h6>{{ data.user.first_name || '' }} {{ data.user.last_name || '' }}</h6>
                      <span class="text-muted">{{ data.user_role || '' }}</span>
                    </b-media>
                  </div>
                  <h6
                    v-else
                    class="font-weight-bolder"
                  >
                    {{ data.user }}
                  </h6>
                </app-timeline-item>
              </b-col>
              <b-col
                v-if="financeTimelineData.warehouse_deposit"
                :cols="isWalkingDeposit ? 6 : 12"
              >
                <div
                  class="shadow rounded-sm mb-3 w-100 bg-light-secondary"
                  style="padding:10px;"
                >
                  <div class="font-small-5 font-weight-bold text-center text-dark">
                    Balance Deposit - Ref No : {{ financeTimelineData.warehouse_deposit[0].ref_no }}
                  </div>
                </div>
                <app-timeline-item
                  v-for="(data, index) in financeTimelineData.warehouse_deposit"
                  :key="index"
                  :variant="'light-'+data.status.color ? data.status.color : 'primary'"
                  :icon="data.status.icon"
                >
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <div>
                      <h6>{{ data.status.name }}</h6>
                      <h5 class="font-small-3">
                        {{ data.deposit_amount }}
                      </h5>
                    </div>
                    <small class="text-muted">{{ __dateTimeFormatter(data.date_time) }} | {{ data.date_time_ago }}</small>
                  </div>
                  <p>{{ data.reason || '' }}</p>
                  <div
                    v-if="data.user !== 'Automatically Generated'"
                    class="d-flex flex-sm-row flex-column justify-content-between align-items-start"
                  >
                    <b-media>
                      <template #aside>
                        <b-avatar
                          :src="`https://picsum.photos/200/200?random=${index}`"
                          class="mr-30"
                          variant="light-success"
                        />
                      </template>
                      <h6>{{ data.user.first_name || '' }} {{ data.user.last_name || '' }}</h6>
                      <span class="text-muted">{{ data.user_role || '' }}</span>
                    </b-media>
                  </div>
                  <h6
                    v-else
                    class="font-weight-bolder"
                  >
                    {{ data.user }}
                  </h6>
                </app-timeline-item>
              </b-col>
            </b-row>
          </app-timeline>
          <div
            v-else
            class="d-flex justify-content-center align-items-center"
          >
            This order has no recorded warehouse deposit history
          </div>
        </b-tab>
        <b-tab
          title="Invoice History"
          class="px-2"
        >
          <app-timeline v-if="invoiceTimelineData.length>0">
            <div
              class="shadow rounded-sm mb-3 w-100 bg-light-secondary"
              style="padding:10px;"
            >
              <div class="font-small-5 font-weight-bold text-center text-dark">
                Invoice No : {{ invoiceTimelineData[0].invoice_no }}
              </div>
            </div>
            <app-timeline-item
              v-for="(data, index) in invoiceTimelineData"
              :key="index"
              :variant="'light-'+data.status.color ? data.status.color : 'primary'"
              :icon="data.status.icon"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <div>
                  <h6>{{ data.status.name }}</h6>
                  <h5 class="font-small-3">
                    {{ data.invoice_amount }}
                  </h5>
                </div>
                <small class="text-muted">{{ __dateTimeFormatter(data.date_time) }} | {{ data.date_time_ago }}</small>
              </div>
              <p>{{ data.reason || '' }}</p>
              <div
                v-if="data.user !== 'Automatically Generated'"
                class="d-flex flex-sm-row flex-column justify-content-between align-items-start"
              >
                <b-media>
                  <template #aside>
                    <b-avatar
                      :src="`https://picsum.photos/200/200?random=${index}`"
                      class="mr-30"
                      variant="light-success"
                    />
                  </template>
                  <h6>{{ data.user.first_name || '' }} {{ data.user.last_name || '' }}</h6>
                  <span class="text-muted">{{ data.user_role || '' }}</span>
                </b-media>
              </div>
              <h6
                v-else
                class="font-weight-bolder"
              >
                {{ data.user }}
              </h6>
            </app-timeline-item>
          </app-timeline>
          <div
            v-else
            class="d-flex justify-content-center align-items-center"
          >
            This order has no recorded Invoice history
          </div>
        </b-tab>
        <b-tab :title="`General Remarks (${order.remark.length})`">
          <remark-table
            :remarks="order.remark"
            :order-id="id"
          />
        </b-tab>
        <b-tab
          v-if="order.is_reversed_order === true"
          v-can="'view-reversal'"
          title="Reversed Details"
        >
          <reversed-details
            :order_id="id"
          />
        </b-tab>
      </b-tabs>

    </b-overlay>
    <b-modal
      id="signature-view"
      v-model="signatureOpen"
      size="sm"
      centered
      hide-footer
      title="VIEW SIGNATURE"
    >
      <div class="signature-container">

        <img
          :src="`${cdn}${order.rider_signature}`"
          alt="Rider's Signature"
          class="signature"
        >
        <!-- <b-button
          class="signature-button"
          variant="outline-primary"
          size="sm"
          @click="downloadImg(`${cdn}${order.rider_signature}`, `${order.waybill_number}`)"
        >Download</b-button> -->
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BTabs,
  BTab,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BMedia,
  BButton,
} from 'bootstrap-vue'
import OrderStatusBanner from '@core/components/status/OrderStatusBanner.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import displayAmountWithCurrency from '@core/mixins/currency'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ReversedDetails from '@/components/AllOrders/reversedDetails.vue'
import RemarkTable from '@/components/AllOrders/Remark/RemarkTable.vue'
import { avatarText } from '@core/utils/filter'
import emptyOrderPayload from '@/models/order'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import StarRating from 'vue-star-rating'

const OrderRepository = RepositoryFactory.get('order')
const feedBackRepository = RepositoryFactory.get('feedback')

export default {
  name: 'ViewOrderModal',
  directives: {
    'b-modal': VBModal,
  },
  components: {
    BModal,
    BOverlay,
    BTabs,
    BTab,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BMedia,
    BButton,
    StarRating,
    OrderStatusBanner,
    AppTimeline,
    AppTimelineItem,
    ReversedDetails,
    RemarkTable,
  },
  mixins: [SuccessMessage, ErrorMessage, displayAmountWithCurrency],
  data() {
    return {
      avatarText,
      open: false,
      signatureOpen: false,
      modalLoading: false,
      isWalkingDeposit: false,
      id: null,
      items: {},
      perPage: 10,
      timelineData: [],
      financeTimelineData: [],
      invoiceTimelineData: [],
      order: emptyOrderPayload(),
      fields: [
        { key: 'remarkedBy', label: 'Remarked By', sortable: true },
        { key: 'remark', label: 'Remark', sortable: false },
        { key: 'created_at', label: 'Remark Date', sortable: false },
      ],
      cdn: process.env.VUE_APP_BUCKET_URL,
      myVariant: '',
    }
  },
  methods: {
    async fetchFeedbackDetails() {
      try {
        const { data } = (await feedBackRepository.getFeedbackDetails(this.id)).data
        this.items = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async openModal(id) {
      this.open = true
      this.id = id
      this.modalLoading = true
      await this.getOrderTimeline()
      await this.getOrderFinanceTimeline()
      await this.getOrderInvoiceTimeline()
      await this.getOrderDetails()
      await this.fetchFeedbackDetails()
      this.modalLoading = false
    },
    openSignatureModal() {
      this.signatureOpen = true
    },
    async getOrderTimeline() {
      try {
        const { data } = await OrderRepository.getOrderTimeLine(this.id)
        this.timelineData = data.data
      } catch (e) {
        this.timelineData = []
      }
    },
    async getOrderFinanceTimeline() {
      try {
        const { data } = await OrderRepository.getOrderFinanceTimeLine(this.id)
        this.financeTimelineData = data.data
        if (this.financeTimelineData.walking_warehouse_deposit) {
          if ((this.financeTimelineData.walking_warehouse_deposit).length > 0) {
            this.isWalkingDeposit = true
            this.financeTimelineData.walking_warehouse_deposit.push(this.getStatusCopy(data.data.walking_warehouse_deposit.slice().pop()))
          }
        }
        if (this.financeTimelineData.staff_deposit) {
          if (this.financeTimelineData.staff_deposit.length > 0) {
            this.financeTimelineData.staff_deposit.push(this.getStatusCopy(data.data.staff_deposit.slice().pop()))
          }
        }
        if (this.financeTimelineData.warehouse_deposit) {
          if (this.financeTimelineData.warehouse_deposit.length > 0) {
            this.financeTimelineData.warehouse_deposit.push(this.getStatusCopy(data.data.warehouse_deposit.slice().pop()))
          }
        }
      } catch (e) {
        this.financeTimelineData = []
      }
    },
    async getOrderInvoiceTimeline() {
      try {
        const { data } = await OrderRepository.getOrderInvoiceTimeLine(this.id)
        this.invoiceTimelineData = data.data
        if (this.invoiceTimelineData.length > 0) {
          this.invoiceTimelineData.push(this.getStatusCopy(data.data.slice().pop()))
        }
      } catch (e) {
        this.invoiceTimelineData = []
      }
    },
    async getOrderDetails() {
      try {
        const { data } = (await OrderRepository.getOrderDetailsById(this.id)).data
        this.order = data
        this.myVariant = this.order.status.color
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    getFlagBadgeColor(type) {
      if (type === 'flag_1') {
        return 'light-info'
      }
      if (type === 'flag_2') {
        return 'light-danger'
      }
      if (type === 'flag_3') {
        return 'light-primary'
      }
      if (type === 'flag_4') {
        return 'light-dark'
      }
      if (type === 'flag_5') {
        return 'light-success'
      }
      if (type === 'flag_6') {
        return 'light-warning'
      }
      if (type === 'flag_7') {
        return 'light-danger'
      }
      if (type === 'flag_8') {
        return 'light-info'
      }
      return 'light-primary'
    },
    getStatusCopy(status) {
      const pendingCopy = JSON.parse(JSON.stringify(status))
      pendingCopy.status.name = 'Created'
      pendingCopy.status.color = 'success'
      return pendingCopy
    },
    downloadImg(urlFromFunction, wayBillNumber) {
      const xhr = new XMLHttpRequest()
      const fileName = `${wayBillNumber}_customer_signature.png`
      const dataType = 'png'
      xhr.onload = function c() {
        const arr = new Uint8Array(this.response)
        const raw = String.fromCharCode.apply(null, arr)
        const b64 = btoa(raw)
        const dataURL = `data:image/${dataType};base64,${b64}`
        const element = document.createElement('a')
        element.href = dataURL
        element.setAttribute('download', fileName)
        document.body.appendChild(element)
        element.click()
        element.remove()
      }
      xhr.open('GET', urlFromFunction)
      xhr.responseType = 'arraybuffer'
      xhr.send()
    },
  },
}
</script>

<style scoped >
@media (max-width: 769px) {
  .modal {
    width: 100%;
  }
}
  .signature-container {
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
  }
  .signature{
    min-height: 150px;
    min-width: 150px;
  }
  .signature-button{
    margin: 1.75rem 0 0.25rem 0;
  }
  .view-sign-button {
    margin: 0 0 1.25rem 0;
  }

  h5 {
    color: #000000;
  }
  .order-status-name {
    color: white;
  }
  .text-container {
    max-width: 100%; /* Set your desired maximum width */
    word-wrap: break-word; /* Break long words to the next line */
    white-space: normal; /* Allow whitespace to wrap */
  }
  .date-container {
    max-width: 120px; /* Set your desired maximum width */
    word-wrap: break-word; /* Break long words to the next line */
    white-space: normal; /* Allow whitespace to wrap */
    text-align: right;
    margin-top: 7px;
    font-weight: 500;
  }
  .col-container {
    width: 100%;
    min-height: 100%;
  }
  .sub-title{
    font-size:large;
  }
  p {
    font-weight: bold;
  }
.status-bar {
  padding: 1rem;
}

@media (max-width: 768px) {
  .status-bar {
    padding: 0.5rem;
  }
  .col-container {
    display: flex;
    flex-direction: column;
  }
  #modal-order-view {
      max-width: 100%;
      width: 100%;
    }
    .view-sign-button {
    display: block;
  }
  .date-container {
    text-align: left;
  }
  .mobile-column {
    width: 100% !important;
    margin-bottom: 1rem;
  }
}
</style>
