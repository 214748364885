<template>
  <div style="width: 150px">
    <b-badge
      :variant="'light-' + statusColor"
      class="status-badge text-container"
    >
      {{ statusName }}
    </b-badge>
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'
import {
  mapGetters,
} from 'vuex'

export default {
  name: 'OrderStatusBadge',
  components: {
    BBadge,
  },
  props: {
    statusKey: {
      type: String,
      default: 'N/A',
    },
  },
  computed: {
    ...mapGetters(['statusList']),
    statusColor() {
      const obj = this.statusList.find(n => n.key === this.statusKey)
      return obj ? obj.color : 'primary'
    },
    statusName() {
      const obj = this.statusList.find(n => n.key === this.statusKey)
      return obj ? obj.name : 'N/A'
    },
  },
}
</script>

<style scoped>
@media (max-width: 767px) { /* Apply the style only for screens up to 767px wide (mobile) */
  .status-badge {
    display: inline-block;
    white-space: normal;
  }
}
.text-container {
  max-width: 100%; /* Set your desired maximum width */
  word-wrap: break-word; /* Break long words to the next line */
  white-space: normal; /* Allow whitespace to wrap */
}
</style>
<!-- <style scoped>
    /*.status-badge {*/
    /*    color: white;*/
    /*    padding-left: 10px;*/
    /*    padding-right: 10px;*/
    /*    padding-top: 2px;*/
    /*    padding-bottom: 2px;*/
    /*    font-weight: normal;*/
    /*    font-size: smaller;*/
    /*    display: inline-block;*/
    /*}*/
</style> -->
