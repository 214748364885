import { RepositoryFactory } from '@/repository/RepositoryFactory'

const waybillSettingsRepository = RepositoryFactory.get('waybillSetting')

export default {
  methods: {
    async getWaybillSeriesList() {
      try {
        const { data } = (await waybillSettingsRepository.getWaybillSeriesListNoPagination(this.filterQuery))
        this.waybillSeriesList = data.data
        this.prefixList = this.waybillSeriesList.map(n => n.prefix)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    checkLength(waybillNumber) {
      this.matchingSeries = this.waybillSeriesList.filter(n => n.preview.length === waybillNumber.length)
    },
    checkPrefix(waybillNumber) {
      const match = waybillNumber.match(/^[A-Za-z]+/)
      if (match) {
        this.prefixMatch = this.matchingSeries.filter(n => n.prefix === match[0])
      }
    },
  },
}
