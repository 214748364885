<template>
  <b-form-input
    v-if="formInput.type === 'input' || formInput.type === 'number'"
    v-model="data"
    :disabled="loading || disabled"
    :size="formInput.size||'md'"
    :class="formInput.inputClass || 'w-full'"
    :type="formInput.inputType || formInput.type"
    :placeholder="formInput.placeholder"
    :icon="formInput.icon"
    :state="errors.length > 0 ? false:null"
    :readonly="formInput.readonly"
    step="0.01"
    autocomplete="nope"
    @paste="customPaste"
    @keydown="handleKeyDown"
  />
  <b-form-textarea
    v-else-if="formInput.type === 'textarea'"
    v-model="data"
    :disabled="loading || disabled"
    :size="formInput.size||'md'"
    :class="formInput.inputClass || 'w-full mb-0'"
    :placeholder="formInput.placeholder"
    :state="errors.length > 0 ? false:null"
    :readonly="formInput.readonly"
    :rows="formInput.rows"
  />
  <b-form-select
    v-else-if="formInput.type === 'select'"
    v-model="data"
    :disabled="loading || disabled"
    :size="formInput.size||'md'"
    :class="formInput.inputClass || 'w-full'"
    :placeholder="formInput.placeholder"
    :clearable="formInput.clearable || false"
    :state="errors.length > 0 ? false:null"
    :readonly="formInput.readonly"
  >
    <!-- :options="formInput.options" -->
    <b-form-select-option
      v-for="option in formInput.options"
      :key="option.label"
      :name="option.label"
      :value="option.value"
    >
      {{ option.label }}
    </b-form-select-option>
  </b-form-select>
  <b-form-checkbox
    v-else-if="formInput.type === 'checkbox' || formInput.type === 'switch'"
    v-model="data"
    :size="formInput.size||'md'"
    :switch="formInput.type === 'switch'"
    :disabled="loading || disabled"
    :state="errors.length > 0 ? false:null"
    :readonly="formInput.readonly"
  />
  <v-select
    v-else-if="formInput.type === 'v-select'"
    v-model="data"
    :options="formInput.options"
    :size="formInput.size||'md'"
    :class="formInput.inputClass"
    :placeholder="formInput.placeholder"
    :disabled="loading || disabled"
    :state="errors.length > 0 ? false:null"
    :readonly="formInput.readonly"
  />
  <v-select
    v-else-if="formInput.type === 'v-select-search' && formInput.searchFunc"
    v-model="data"
    :filterable="false"
    :size="formInput.size||'md'"
    :options="formInput.options"
    :class="formInput.inputClass"
    :placeholder="formInput.placeholder"
    :state="errors.length > 0 ? false:null"
    :disabled="formInput.readonly"
    :multiple="formInput.multiple"
    @search="formInput.searchFunc"
  >
    <template v-slot:option="slot">
      <portal-target
        :name="formInput.value"
        :slot-props="{slotData: slot}"
        slim
      >
        {{ slot.label }}
      </portal-target>
    </template>
    <template slot="no-options">
      {{ formInput.noOptions || "No Result Found" }}
    </template>
  </v-select>
  <b-form-datepicker
    v-else-if="formInput.type === 'date'"
    v-model="data"
    :size="formInput.size||'md'"
    :min="formInput.min"
    :max="formInput.max"
    :date-disabled-fn="formInput.dateDisabled"
    :class="formInput.inputClass"
    :disabled="loading"
    :right="formInput.right"
    :button-only="formInput.btn_only"
    :today-button="formInput.today_btn"
    :reset-button="formInput.reset_btn"
    :close-button="formInput.close_btn"
    :placeholder="formInput.placeholder"
    :state="errors.length > 0 ? false:null"
    :readonly="formInput.readonly"
  />
  <flat-pickr
    v-else-if="formInput.type === 'flat-picker'"
    v-model="data"
    :readonly="formInput.readonly"
    :class="formInput.inputClass || 'form-control'"
    :config="formInput.config"
  />
</template>

<script>

import {
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BFormDatepicker,
  BFormSelectOption,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelectOption,
  },
  props: {
    value: undefined,
    formInput: {
      type: Object,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      data: this.value,
    }
  },
  watch: {
    value(value) {
      this.data = value
    },
    data(value) {
      let dataValue = value
      if (this.formInput.filter) {
        dataValue = this.formInput.filter(dataValue)
      }
      this.$emit('input', dataValue)
    },
  },
  async mounted() {
    if (this.formInput.fetch || this.formInput.fetchWithData) {
      await this.fetchData()
    }
    if (['select', 'v-select'].includes(this.formInput.type) && this.data) {
      this.data = this.formInput.options.find(x => x.value === this.data)
    }
    if (this.formInput.default !== undefined) {
      if (['select', 'v-select'].includes(this.formInput.type)) this.formInput.options.unshift(this.formInput.default)
      if (!this.data) this.data = this.formInput.default
    }
    if (['v-select-search'].includes(this.formInput.type)) {
      this.$set(this.formInput, 'searchFunc', this.lodash.debounce(async (search, loading) => {
        loading(true)
        let response = null
        if (this.formInput.fetchWithData) {
          response = (await this.formInput.fetchWithData(this.formData, search)).data.success
        } else {
          response = (await this.formInput.fetch(search)).data.success
        }
        if (this.formInput.fetchFilter) response = response.map(item => this.formInput.fetchFilter(item))
        this.$set(this.formInput, 'options', response)
        loading(false)
      }, 350))
    }
  },
  methods: {
    customPaste(event) {
      if (this.formInput.value === 'waybill_number') {
        // Access the pasted value from the event
        const pastedText = event.clipboardData.getData('text')
        this.$emit('builderPaste', pastedText)
      }
    },
    handleKeyDown(event) {
      if (this.formInput.value === 'waybill_number') {
        if (event.key === 'Enter') {
          this.getData()
        }
      }
    },
    getData() {
      this.$emit('builderKeyDown', this.value)
    },
    async fetchData() {
      this.loading = true
      try {
        let response = null
        if (this.formInput.fetchData) {
          response = this.formInput.fetchData
        } else if (!this.formInput.readonly) {
          if (this.formInput.fetchWithData) {
            response = (await this.formInput.fetchWithData(this.formData)).data.data
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof this.formInput.fetch === 'object') {
              response = (await this.formInput.fetch).data.data
            } else {
              response = (await this.formInput.fetch()).data.data
            }
          }
        }
        if (this.formInput.fetchFilter) response = response.map(item => this.formInput.fetchFilter(item))
        this.$set(this.formInput, 'options', response)
      } catch (error) {
        // this.convertAndNotifyError(error)
        // console.log(error)
      }
      this.loading = false
    },
  },
}
</script>

<style>
    .vs__dropdown-toggle {
        height: 37px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
