const emptyOrderPayload = () => ({
  status: {},
  merchant_business: {},
  merchant: {},
  destination_warehouse: {},
  origin_warehouse: {},
  destination_city: {},
  origin_city: {},
  order_flags: [],
  remark: [],
})

export default emptyOrderPayload
